:root {
  --green-2: #c8e7e7;
  --green-6: #1a9999;
  --grey-1: #f0f1f3;
  --grey-2: #dadee3;
  --grey-5: #808da0;
  --white: #fafafa;
  --text-dark: #2d343f;
  --text-light: #475263;
  --text-medium: clamp(2rem, 1.5vw, 3rem);
  --text-subheading: clamp(3rem, 2vw, 5rem);
  --text-body: 2rem;
  --gap-3: 3rem;
}
