.reviews {
  height: 500px;
  margin: 14rem clamp(2rem, 4vw, 8rem);
  padding: 0 2em 2em;
  border-radius: 1em;
  height: auto;
  display: flex;
  gap: 2.4em;

  background: linear-gradient(
    90deg,
    var(--green-2) 0%,
    var(--grey-1) 50%,
    var(--green-2) 100%
  );

  .review {
    display: flex;
    flex-basis: 33%;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    margin-top: -33px;
    text-align: center;
    font-weight: 300;
    padding: 0 2em;
    font-size: var(--text-body);
    color: var(--text-dark);

    .text {
      line-height: 3rem;
    }

    .name {
      font-size: var(--text-medium);
      font-weight: 500;
    }
  }
}
