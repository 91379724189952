header {
  display: flex;
  margin-bottom: 4em;
  position: relative;

  .overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .mask {
      background-color: var(--grey-1);
      height: 50px;

      /* this is 7 times the font size of the H2, 'WELCOME', a seven letter word */
      width: calc(7 * clamp(5rem, 2vw, 6rem));
    }

    h2,
    .mask,
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: aqua;
    }
    .loader {
      height: 2px;
      width: 0;
      background-color: var(--green-6);
    }

    h2 {
      z-index: 99;
      color: var(--green-6);
      font-size: clamp(5rem, 2vw, 6rem);
      font-weight: 700;
      letter-spacing: 8px;
    }
  }
}
header .content {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  padding-left: clamp(2rem, 4vw, 8rem);
}
header .contacts {
  display: flex;
  gap: 4rem;
  margin-top: 4rem;
  height: 20%;
  align-items: flex-start;
}
header .contacts div {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: var(--text-medium);
  color: var(--text-dark);
}
header h1 {
  color: var(--green-6);
  font-size: clamp(8rem, 7vw, 14rem);
  margin-bottom: 3rem;
  font-weight: 700;
  line-height: 95%;
}
header h2 {
  margin-bottom: 2rem;
  text-transform: uppercase;
}
header p {
  font-size: var(--text-medium);
  margin-bottom: 5rem;
  font-weight: 300;
  color: var(--text-dark);
}
header .btns {
  display: flex;
  gap: 3rem;
}

header .illustration img {
  flex: 0 0 55%;
  width: 100%;

  -o-object-fit: cover;
  object-fit: cover;
  border-bottom-left-radius: 10px;
}
