.map {
  display: flex;
  justify-content: space-around;
  padding: 0 clamp(2rem, 4vw, 6rem);
  gap: 6em;
  margin-bottom: 8rem;

  .contact {
    flex-basis: 40%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 3em;
    gap: 5em;

    h3 {
      font-size: var(--text-subheading);
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: underline;
    }
    .cta {
      width: 75%;
      padding: 0;
    }

    .details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 2.4em;
      gap: 3em;
      padding: 3rem;

      .detail {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .text-light {
          color: var(--grey-5);
          font-size: 2rem;
        }

        div {
          display: flex;
          align-items: center;
          margin-left: -5px;
          gap: 1em;
        }
      }
    }
  }

  .map-frame {
    flex-basis: 60%;
    flex-grow: 1;
    border-radius: 1.5em;
  }
}
