.info-gallery {
  display: flex;
  justify-content: flex-end;
}
.info-gallery .info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3em;
}
.info-gallery .gallery {
  flex: 0 0 45%;
  max-height: 100%;
  padding: 0 clamp(2rem, 4vw, 8rem);
  display: flex;
  align-items: center;
  gap: 2em;

  img {
    border-radius: 10px;
    width: 100%;
    max-height: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .left {
    display: flex;
    gap: 2em;
    max-height: 100%;
    flex-direction: column;
  }
}

.info-gallery .card {
  flex-grow: 1 1 auto;
  background-color: var(--white);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 3.5rem 7rem 3.5rem 3.5rem;
}
.info-gallery .card h3 {
  color: var(--text-dark);
  font-size: var(--text-subheading);
  font-weight: 500;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  margin-bottom: var(--gap-3);
}
.info-gallery .card p {
  font-size: var(--text-body);
  line-height: 3.5rem;
  color: var(--text-light);
  font-weight: 300;
}
