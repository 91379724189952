@import url("./vars.css");
@import url("./header.css");
@import url("./info_gallery.css");
@import url("./reviews.css");
@import url("./map.css");
@import url("./footer.css");
@import url("./loader.css");
html {
  font-size: 50%; /*1rem = 8px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: calc(1em + 0.5rem);
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3 {
  color: var(--text-dark);
}

h2 {
  font-size: var(--text-subheading);
  font-weight: 500;
  letter-spacing: 0.04em;
}

.cta {
  display: inline-block;
  height: 3.5em;
  line-height: 3.5em; /*equal line-height centers text vertically*/
  font-size: 2.5rem;
  color: white;
  background-color: var(--green-6);
  text-decoration: none;
  border-radius: 10px;
  padding: 0 3rem;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 3px 3px 3px var(--grey-2);
  letter-spacing: 0.04em;
}

body {
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  font-family: "Urbanist", sans-serif;
  background-color: var(--grey-1);
}
