footer {
  background: var(--white);
  height: 8rem;
  line-height: 8rem;
  margin: 4rem clamp(4rem, 4vw, 6rem);
  border-radius: 1em;
  text-align: center;

  p {
    color: var(--text-dark);
    font-size: var(--text-body);
    display: inline;
  }
}
